/* poppins-200 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../../src/fonts/poppins-v9-latin-200.eot'); /* IE9 Compat Modes */
  src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'),
  url('../../src/fonts/poppins-v9-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../src/fonts/poppins-v9-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../src/fonts/poppins-v9-latin-200.woff') format('woff'), /* Modern Browsers */
  url('../../src/fonts/poppins-v9-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../src/fonts/poppins-v9-latin-200.svg#Poppins') format('svg'); /* Legacy iOS */
}


/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../../src/fonts/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
  url('../../src/fonts/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../src/fonts/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../src/fonts/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../../src/fonts/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../src/fonts/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../../src/fonts/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
  url('../../src/fonts/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../src/fonts/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../src/fonts/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../../src/fonts/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../src/fonts/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}



@font-face {
  font-family: 'luanna_icon';
  src:  url('../../src/fonts/luanna_icon.eot?7su363');
  src:  url('../../src/fonts/luanna_icon.eot?7su363#iefix') format('embedded-opentype'),
  url('../../src/fonts/luanna_icon.ttf?7su363') format('truetype'),
  url('../../src/fonts/luanna_icon.woff?7su363') format('woff'),
  url('../../src/fonts/luanna_icon.svg?7su363#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'luanna_icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon_twitter:before {
  content: "\e904";
  color: $white;
}
.icon-icon_facebook:before {
  content: "\e900";
  color: $white;
}
.icon-icon_homify:before {
  content: "\e901";
  color: $white;
}
.icon-icon_houzz:before {
  content: "\e902";
  color: $white;
}
.icon-icon_instagram:before {
  content: "\e903";
  color: $white;
}
