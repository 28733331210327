footer {
  padding: 60px 0;
  background-color: $secondary;

  .impress {
    float: left;
    @include media-breakpoint-down(sm) {
      float: none;
      margin-bottom: 3rem;
    }
  }

  .logo {
    width: 118px;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  p {
    font-size: 14px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 1rem;
    }
  }

  ul {
    &.menu {
      text-transform: uppercase;
      a {
        font-weight: 900;
        font-size: 18px;
        transition: all 250ms ease;
        &.active {
          color: $white;
        }
        &:hover {
          color: $white;
          text-decoration: underline;
        }
      }
    }
    &.socialmedia {
      @include media-breakpoint-down(sm) {
        float: none;
        margin-top: 2rem;
      }
      a {
        color: $white;
        font-size: 14px;
      }
      i {
        margin-right: 0.5rem;
      }
    }
  }

  ul.level1 > li {
    margin-bottom: 1rem;
    a {
      font-weight: 500;
    }
  }
}
