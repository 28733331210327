.testbgimg {
  width: 100%;
  height: 2000px;
  background-image: url("../img/bg.jpg");
  background-size: contain;
}

.testbgimg2 {
  width: 100%;
  height: 2000px;
  background-size: contain;
}
