.hamburger-wrapper {
  display: flex;
  list-style: none;
  flex-flow: row nowrap;
  flex-direction:row;
  justify-content: space-between;
  align-items: center;

  .hamburger {
    display: none;
    cursor: pointer;
    width: 48px;
    height: 38px;
    position: relative;
    overflow: hidden;
    transition: all 300ms ease;
    @include media-breakpoint-down(lg) {
      display: inline-block;
    }
    span {
      position: absolute;
      width: 36px;
      height: 4px;
      background-color: $primary;
      display: block;
      left: 6px;
      transition: all 300ms ease;
      opacity: 1;
    }
    span:nth-of-type(1) {
      top: 8px
    }
    span:nth-of-type(2) {
      top: 17px
    }
    span:nth-of-type(3) {
      top: 26px
    }

    &.open {
      span:nth-of-type(1) {
        top: 17px;
        transform: rotate(45deg);
      }
      span:nth-of-type(2) {
        left: -6px;
        opacity: 0;
      }
      span:nth-of-type(3) {
        top: 17px;
        transform: rotate(-45deg);
      }
    }
  }

}

