section.image-matrix-desktop {
  .loading {
    margin-bottom: 2rem;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
}
section.image-matrix-mobile {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

