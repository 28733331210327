nav.desktop {
  @include media-breakpoint-down(md) {
    display: none;
  }
  .wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .logo {
    width: 150px;
    position: absolute;
    left: 15px;
    background-color: $gray-900;
    border-bottom: 1px solid $gray-600;
    a {
      display: block;
      padding: 20px;
      img {
        width: 100%;
      }
    }
  }
  .menu {
    ul {
      margin-bottom: 0;
      padding-left: 0;
      display: flex;
      list-style: none;
      flex-flow: row nowrap;
      flex-direction:row;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      li {
        height: 100%;
        width: 100%;
        text-align: center;
        a {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          transition: all 250ms ease;
          padding: 20px;
          text-decoration: none;
          text-transform: uppercase;
          font-family: Roboto;
          font-weight: 500;
          letter-spacing: 1px;
          line-height: 28px;
          &:hover {
            color: $white;
            text-decoration: underline;
          }
          &.active {
            color: $white;
          }
        }
      }
    }
  }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  nav.desktop {
    .wrapper {
      flex-direction: row;
    }
    .logo {
      a {
        img {
          width: 119px;
        }
      }
    }
  }
}


