section.boxes {
  .margin {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  img {
    width: 100%;
  }
  .box {
    background-color: $gray-200;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .boxlink {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      font-size: 0;
      color: transparent;
    }
    .content {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      .text {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .headline {
        flex: none;
        position: relative;
      }
    }
  }
}
