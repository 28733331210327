h1, h2 {
  @include media-breakpoint-down(md) {
    font-size: 30px;
    line-height: 38px;
  }
}

h2 {
  letter-spacing: 0.75px;
  line-height: 46px;
  margin-bottom: 1.25rem;
}

h3 {
  letter-spacing: 0.75px;
  line-height: 30px;
}

h4 {
  color: $gray-600;
  font-family: Roboto;
  letter-spacing: 0.5px;
  line-height: 28px;
  font-weight: 500;
}

p {
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 30px;
}

.display-1 {
  font-family: Poppins;
  @include media-breakpoint-down(md) {
    font-size: 30px;
  }
}


h1, .display-1 {
  letter-spacing: 0.75px;
  line-height: 65px;
}


img {
  width: 100%;
}

.btn {
  margin-top: 2rem;
  min-width: 538px;
  padding-top: 11px;
  padding-bottom: 11px;
  color: $white;
  text-transform: uppercase;
  &:hover {
    color: $white;
  }
  @include media-breakpoint-down(md) {
    width: 100%;
    min-width: auto;
  }
}
