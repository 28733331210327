#cookielaw {
  background-color: black;
  color: $white;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px 15px;
  z-index: 9001;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translateY(100%);
  transition: transform 1s;
  p {
    margin: 0 15px 0 0;
    padding: 0;
    display: inline-block;
    color: $white;
  }
  #cookielawlink {
    white-space: nowrap;
    color: $white;
    text-decoration: underline;
    transition: background-color .25s ease-out,color .25s ease-out;
    &:focus, &:hover {
      color: $white
    }
  }
  #cookielawbutton {
    padding: 10px 20px;
    background-color: transparent;
    color: $white;
    border-radius: 0px;
    white-space: nowrap;
    text-decoration: none;
    transition: background-color .25s ease-out,color .25s ease-out;
    border: 2px solid $white;
    margin: 0;
    min-width: 70px;
    span {
      background-color: $white;
    }
  }
}