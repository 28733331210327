section.masonry {
  .grid-item {
    width: 33.33333333%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    @include media-breakpoint-down(md) {
      width: 50%;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  img,h4,h3,hr {
    margin-bottom: 1.25rem;
  }
  hr {
    border-top: 1px solid $gray-600;
    margin-top: 1.25rem;
  }
}
