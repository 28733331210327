header {
  .mobile {
    @include media-breakpoint-up(lg) {
      display: none;
    }
    .wrapper {
      display: flex;
      flex-direction:row;
      justify-content: space-between;
    }
    .logo {
      width: 100px;
      a {
        display: block;
        padding: 15px 0;
        margin-right: 20px;
        img {
          width: 100%;
        }
      }
    }
  }
}

nav.mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $secondary;
  z-index: 9;
  transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate(-100%);
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  padding-bottom: 110px;
  padding-top: 110px;
  &::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }
  &.open {
    transform: translate(0%);
  }
  .opener {
    transition: height .25s ease;
    overflow: hidden;
    padding-left: 30px;
    &:not(.open) {
      display: none;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      a {
        display: block;
        cursor: pointer;
        position: relative;
        text-transform: uppercase;
        &:hover, &:active {
          color: $white;
          text-decoration: none;
        }
        &.active {
          color: $white;
        }
      }
    }
  }
  ul.level1 > li {
    border-bottom: 1px solid $gray-600;
  }
  ul.level1 > li > a {
    font-size: 18px;
    padding: 20px 0;
    font-weight: 500;
  }
}

.mobile-nav-bounce-fixer {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  background-color: $secondary;
  display: none;
  &.open {
    display: block;
  }
}
