header {
  background-color: $gray-900;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  .line {
    border-bottom: 1px solid $gray-600;
    margin-left: 15px;
    margin-right: 15px;
  }
}
