section.images-in-grids {
  .info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 100%;
  }
  .wrapper{
    .boxlink {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 3;
      font-size: 0;
      color: transparent;
    }
  }
  img {
    width: 100%;
  }
}
