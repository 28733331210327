.divider {
  position: relative;
  .center {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $secondary;
    padding-left: 15px;
    padding-right: 15px;
  }
  .div {
    height: 1px;
    width: 100%;
    background-color: $primary;
    z-index: 0;
  }
  .gradient {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#262525+0,262525+100&1+0,0+35,0+65,1+100 */
    background: -moz-linear-gradient(left,  rgba(38,37,37,1) 0%, rgba(38,37,37,0) 35%, rgba(38,37,37,0) 65%, rgba(38,37,37,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(38,37,37,1) 0%,rgba(38,37,37,0) 35%,rgba(38,37,37,0) 65%,rgba(38,37,37,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(38,37,37,1) 0%,rgba(38,37,37,0) 35%,rgba(38,37,37,0) 65%,rgba(38,37,37,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#262525', endColorstr='#262525',GradientType=1 ); /* IE6-9 */
  }
  &.gray {
    .div {
      background-color: $gray-600;
    }
  }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .divider {
    img {
      width: 29px;
      height: 38px;
    }
  }
}
