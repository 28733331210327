section.img-txt {
  background: url("../img/BG.jpg") no-repeat center center;
  background-size: cover;
  position: relative;
  @include media-breakpoint-down(md) {
    background: none;
  }
  .gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#262525+0,262525+100&0+0,1+100 */
    background: -moz-linear-gradient(top,  rgba(38,37,37,0) 0%, rgba(38,37,37,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(38,37,37,0) 0%,rgba(38,37,37,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(38,37,37,0) 0%,rgba(38,37,37,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00262525', endColorstr='#262525',GradientType=0 ); /* IE6-9 */
    height: 400px;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    @include media-breakpoint-down(md) {
      display: none;
    }

  }
  h4, .display-1 {
    margin-bottom: 2rem;
    @include media-breakpoint-down(md) {
      margin-bottom: 1.25rem;
    }
  }

  .center-horizontal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .loading {
    margin-bottom: 2.4rem;
  }
}
